<section class="poster-three-section">
  <div class="title-row">
    <img src="assets/Group-277.svg" alt="image" loading="lazy" />
    <h3>ماذا يحتوي صندوق برق</h3>
    <img src="assets/Group-556.svg" alt="image" loading="lazy" />
  </div>

  <!-- <section class="section-one">
  <div>
    <app-image
      [images]="[
        {
          alt: 'game',
          src: 'assets/box_new.png',
          width: 586,
          height: 380
        }
      ]"
    ></app-image>
  </div>
  <div>
    <div>
      <div>
        <h4>
          صندوق مليء بالمشاريع التعليمية والتجارب الممتعة،
          <b>يصلك الى باب منزلك</b>
          شهريا...
        </h4>
      </div>
    </div>
  </div>
</section> -->

  <!-- <div class="title-row">
  <img src="assets/header-two/NI-06.png" alt="" />
  <h3>ماذا ستجد داخل الصندوق؟</h3>
  <img src="assets/header-two/NI-06.png" alt="" />
</div> -->

  <section class="list-item">
    <div class="number-image-container">
      <app-image
        [images]="[
          {
            alt: 'game',
            src: 'assets/header-two/settings-icon.png',
            width: 64,
            height: 64
          }
        ]"
      ></app-image>
    </div>
    <div class="content-container" style="text-align: center">
      <h4>
        جميع القطع والأدوات اللازمة ليقوم طفلك بتركيب المشروع كاملاً بنفسه.
      </h4>
      <img
        src="../../../../../assets/poster-three/hyd_parts-lazy.png"
        data-src="../../../../../assets/poster-three/hyd_parts-2.png"
        alt="image"
        class="features__img"
        loading="lazy"
      />
      <!-- <app-image
        [images]="[
          {
            alt: 'game',
            src: 'assets/hyd_parts.png',

            width: 1064,
            height: 483
          }
        ]"
      ></app-image> -->
    </div>
  </section>

  <section class="list-item">
    <div class="number-image-container">
      <app-image
        [images]="[
          {
            alt: 'game',
            src: 'assets/header-two/settings-icon2.png',
            width: 64,
            height: 64
          }
        ]"
      ></app-image>
    </div>
    <div class="content-container">
      <h4>
        دليل الاستعمال والذي يشرح طريقة التركيب خطوة بخطوة ثم مبدأ عمل المشروع.
      </h4>

      <img
        src="../../../../../assets/poster-three/the-user-manual-lazy.png"
        data-src="../../../../../assets/poster-three/the-user-manual.png"
        alt="game"
        class="features__img"
        loading="lazy"
      />
      <!-- <app-image
        [images]="[
          {
            alt: 'game',
            src: 'assets/man_new.png',
            width: 1064,
            height: 502
          }
        ]"
      ></app-image> -->
    </div>
  </section>

  <section class="list-item">
    <div class="number-image-container">
      <app-image
        [images]="[
          {
            alt: 'game',
            src: 'assets/header-two/settings-icon3.png',
            width: 64,
            height: 64
          }
        ]"
      ></app-image>
    </div>
    <div class="content-container">
      <h4>
        بالإضافة إلى مجلة معلومات تناقش المبادئ العلمية المرتبطة بهذا المشروع
        وتقترح تجارب منزلية إضافية.
      </h4>

      <img
        src="../../../../../assets/poster-three/an-information-magazine-lazy.png"
        data-src="../../../../../assets/poster-three/an-information-magazine.png"
        alt="game"
        class="features__img"
        loading="lazy"
      />
      <!-- <app-image
        [images]="[
          {
            alt: 'game',
            src: 'assets/mag_new.png',
            width: 1064,
            height: 530
          }
        ]"
      ></app-image> -->
    </div>
  </section>
</section>
