<section class="header-section">
  <app-header-two></app-header-two>
  <section class="slider-bg">
    <app-products-section></app-products-section>
    <app-poster></app-poster>
  </section>
  <app-poster-three></app-poster-three>
</section>

<app-get-started></app-get-started>

<app-get-products></app-get-products>

<app-footer-two></app-footer-two>
