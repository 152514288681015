import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APIResponse, Order } from 'src/app/models/models';
import { environment } from 'src/environments/environment';
import { HttpNestBase } from './request.class';

@Injectable({
  providedIn: 'root',
})
export class OrdersService extends HttpNestBase {
  constructor() {
    super(environment.API_URL, 'orders');
  }

  orderRelation = [
    { field: 'payment' },
    { field: 'order_items' },
    { field: 'order_items.user_subscription' },
    { field: 'order_items.product' },
    { field: 'order_items.product.main_image' },
    { field: 'order_items.product.tag_image' },
    { field: 'delivery_time_info' },
    { field: 'delivery_time_info.note' },
    { field: 'delivery_time_info.days' },
    { field: 'subscription_group' },
    { field: 'subscription_group.user_subscriptions' },
    { field: 'subscription_group.user' },
    { field: 'subscription_group.address' },
    { field: 'subscription_group.address.city' },
    { field: 'subscription_group.address.country' },
  ];

  getFullOrders(page, limit): Observable<APIResponse<Order>> {
    return this.find()
      .setPage(page)
      .setLimit(limit)
      .setJoin(this.orderRelation)
      .sortBy({ field: 'id', order: 'DESC' })
      .execute<APIResponse<Order>>();
  }

  getOrder(id: number): Observable<Order> {
    return this.get(id).setJoin(this.orderRelation).execute<Order>();
  }

  getLastOrderForGroup(group_id: number): Observable<Order> {
    return this.setSubRoute('last-order')
      .post()
      .setBody({ group_id })
      .execute<Order>();
  }

  async makeSessionOrder(data) {
    console.log(data);
    return await this.setSubRoute('shop-order')
      .post()
      .setBody(data)
      .execute<Order>()
      .toPromise();
  }

  async completeWaitingPayment(orderId: number) {
    return await this.setSubRoute(`completeWaitingPayment/${orderId}`)
      .post()
      .execute<Order>()
      .toPromise();
  }

  async completeOrder(result_indicator: string) {
    return await this.setSubRoute('shop-order-complete')
      .post()
      .setBody({ result_indicator })
      .execute<Order>()
      .toPromise();
  }

  getAvailableProductsForUSerSubscription(userSubscriptionId: number) {
    return this.setSubRoute('get-available-products-for-user-subscription')
      .get(userSubscriptionId)
      .execute();
  }

  getOrderItem(id: number) {
    return this.setSubRoute(`order-item/${id}`).get().execute();
  }

  updateOrderItemProduct(order_item_id: number, product_id: number) {
    return this.setSubRoute(`order-item/${order_item_id}/update-product`)
      .post()
      .setBody({ product_id })
      .execute();
  }

  getShopOrders(id: number) {
    return this.setSubRoute(`user-orders-shop/${id}`).get().execute();
  }
  getShopOrdersById(userid: number, orderId: number) {
    return this.setSubRoute(`user-orders-shop-byId/${userid}/${orderId}`)
      .get()
      .execute();
  }

  getWaitingPaymentOrder(orderId: number) {
    return this.setSubRoute(`get-waiting-payment-order/${orderId}`)
    .get()
    .execute();
  }
}
