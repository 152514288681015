import { Component, ElementRef, OnInit } from '@angular/core';

@Component({
  selector: 'app-poster-three',
  templateUrl: './poster-three.component.html',
  styleUrls: ['./poster-three.component.scss'],
})
export class PosterThreeComponent implements OnInit {
  constructor(private elementRef: ElementRef) {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    //script

    var s = document.createElement('script');
    s.type = 'text/javascript';
    s.src = '/assets/js/lazy-loading-images.js';

    s.async = true;

    this.elementRef.nativeElement.appendChild(s);
  }

  afterScriptAdded() {
    const params = {
      width: '350px',
      height: '420px',
    };

    if ((typeof window as any['functionFromExternalScript']) === 'function') {
      window as any['functionFromExternalScript'];
      params;
    }
  }
}
