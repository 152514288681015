import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageBreakPointDirective } from './image-break-point.directive';

@NgModule({
  declarations: [ImageBreakPointDirective],
  exports: [ImageBreakPointDirective],
  imports: [CommonModule],
})
export class ImageBreakPointModule {}
