<section class="poster-section">
  <div class="title-row">
    <div>
      <h3>لماذا برق؟</h3>
    </div>
  </div>

  <div class="swiper-why-barq">
    <div class="swiper-wrapper">
      <div class="swiper-slide mint">
        <div class="desktop">
          <div class="img-fluid">
            <div class="slide-text-box">
              <div class="slide-text-title-2">
                <p>لتعليم أطفالنا عن طريق اللعب و الاستكشاف</p>
              </div>
              <div class="slide-text-subtitle">
                <p>
                  صندوق مليء بالمشاريع الفنية التعليمية المصممة خصيصا لتطوير
                  مهارات طفلك عن طريق اللعب التجريبي.
                </p>
              </div>
            </div>

            <div class="slide-image-box">
              <img
                src="../../../../../assets/Why-Barq-Section/educate-our-children.png"
                alt="To educate our children through play and exploration."
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div class="mobile">
          <div class="img-fluid">
            <div class="slide-text-box">
              <!-- <div class="slide-text-title-2">
                <p>لتعليم أطفالنا عن طريق اللعب و الاستكشاف</p>
              </div> -->

              <div class="slide-image-box">
                <img
                  src="../../../../../assets/Why-Barq-Section/educate-our-children.png"
                  alt="To educate our children through play and exploration."
                  loading="lazy"
                />
              </div>
              <div class="slide-text-title-2">
                <p>لتعليم أطفالنا عن طريق اللعب و الاستكشاف</p>
              </div>
              <!-- <div class="slide-text-subtitle">
                <p>
                  صندوق مليء بالمشاريع الفنية التعليمية المصممة خصيصا لتطوير
                  مهارات طفلك عن طريق اللعب التجريبي.
                </p>
              </div> -->
            </div>
          </div>
        </div>
      </div>
      <!-- def -->

      <div class="swiper-slide bittersweet">
        <div class="desktop">
          <div class="img-fluid">
            <div class="slide-text-box">
              <div class="slide-text-title-3">
                <p>يساعدك برق على قضاء وقت ممتع مع أطفالك </p>
              </div>
              <div class="slide-text-subtitle">
                <p>
                  لتحفيز الإبداع الطبيعي للأطفال وفضولهم، مع مساعدة الاهل الذين
                  يرغبون في تقديم تجارب ثرية لأطفالهم
                </p>
              </div>
            </div>

            <div class="slide-image-box">
              <img
                src="../../../../../assets/Why-Barq-Section/a-fun-time-with-your-children.png"
                alt="Barq helps you have a fun time with your children."
                loading="lazy"
              />
            </div>
          </div>
        </div>
        <div class="mobile">
          <div class="img-fluid">
            <div class="slide-text-box">
              <!-- <div class="slide-text-title-3">
                <p>يساعدك برق على قضاء وقت ممتع مع أطفالك </p>
              </div> -->

              <div class="slide-image-box">
                <img
                  src="../../../../../assets/Why-Barq-Section/a-fun-time-with-your-children.png"
                  alt="Barq helps you have a fun time with your children."
                  loading="lazy"
                />
              </div>
              <div class="slide-text-title-3">
                <p>يساعدك برق على قضاء وقت ممتع مع أطفالك </p>
              </div>

              <!-- <div class="slide-text-subtitle">
                <p>
                  لتحفيز الإبداع الطبيعي للأطفال وفضولهم، مع مساعدة الاهل الذين
                  يرغبون في تقديم تجارب ثرية لأطفالهم
                </p>
              </div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="swiper-slide mint">
        <div class="desktop">
          <div class="img-fluid">
            <div class="slide-text-box">
              <div class="slide-text-title-2">
                <p>لتعليم أطفالنا عن طريق اللعب و الاستكشاف</p>
              </div>
              <div class="slide-text-subtitle">
                <p>
                  صندوق مليء بالمشاريع الفنية التعليمية المصممة خصيصا لتطوير
                  مهارات طفلك عن طريق اللعب التجريبي.
                </p>
              </div>
            </div>

            <div class="slide-image-box">
              <img
                src="../../../../../assets/Why-Barq-Section/educate-our-children.png"
                alt="To educate our children through play and exploration."
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div class="mobile">
          <div class="img-fluid">
            <div class="slide-text-box">
              <!-- <div class="slide-text-title-2">
                <p>لتعليم أطفالنا عن طريق اللعب و الاستكشاف</p>
              </div> -->

              <div class="slide-image-box">
                <img
                  src="../../../../../assets/Why-Barq-Section/educate-our-children.png"
                  alt="To educate our children through play and exploration."
                  loading="lazy"
                />
              </div>
              <div class="slide-text-title-2">
                <p>لتعليم أطفالنا عن طريق اللعب و الاستكشاف</p>
              </div>
              <!-- <div class="slide-text-subtitle">
                <p>
                  صندوق مليء بالمشاريع الفنية التعليمية المصممة خصيصا لتطوير
                  مهارات طفلك عن طريق اللعب التجريبي.
                </p>
              </div> -->
            </div>
          </div>
        </div>
      </div>

      <div class="swiper-slide fandango">
        <div class="desktop">
          <div class="img-fluid">
            <div class="slide-text-box">
              <div class="slide-text-title-1">
                <p>لإلهام الجيل القادم من المبتكرين</p>
              </div>
              <div class="slide-text-subtitle-1">
                <p>
                  يحتاج العالم العربي إلى مزيد من الابتكار وحل المشكلات . لذلك
                  قررنا أن نبدأ على نطاق صغير - تعليم الأطفال الابتكار وحل
                  المشكلات
                </p>
              </div>
            </div>

            <div class="slide-image-box">
              <img
                src="../../../../../assets/Why-Barq-Section/To-inspire-the-next-generation.png"
                alt="To inspire the next generation of innovators."
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div class="mobile">
          <div class="img-fluid">
            <div class="slide-text-box">
              <!-- <div class="slide-text-title-1">
                <p>لإلهام الجيل القادم من المبتكرين</p>
              </div> -->

              <div class="slide-image-box">
                <img
                  src="../../../../../assets/Why-Barq-Section/To-inspire-the-next-generation.png"
                  alt="To inspire the next generation of innovators."
                  loading="lazy"
                />
              </div>
              <div class="slide-text-title-1">
                <p>لإلهام الجيل القادم من المبتكرين</p>
              </div>
              <!-- <div class="slide-text-subtitle-1">
                <p>
                  يحتاج العالم العربي إلى مزيد من الابتكار وحل المشكلات . لذلك
                  قررنا أن نبدأ على نطاق صغير - تعليم الأطفال الابتكار وحل
                  المشكلات
                </p>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="swiper-pagination-why-barq"></div>
    <!-- <div class="swiper-button-prev"></div>
    <div class="swiper-button-next"></div>  -->
  </div>
</section>
