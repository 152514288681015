<div class="form-container" [ngStyle]="customStyle">
  <div class="form-title" *ngIf="title">
    <span></span>
    <h3>{{ title }}</h3>
  </div>
  <ng-content></ng-content>

  <div class="loader-container" *ngIf="loading">
    <mat-spinner [diameter]="48"></mat-spinner>
  </div>
</div>
