<app-form-container [title]="'تفاصيل الطلب'" class="form-container">
  <div class="head">
    <div class="section-two">
      <div class="box">
        <span class="details">رقم الطلب:</span>
        <span >#{{order?.id}}</span>
      </div>
      <div class="box">
        <span class="details">تاريخ الطلب:</span>
        <span class="details">{{order?.creation_date | date: 'yyyy/MM/dd'}}</span>
      </div>
      <div class="box">
        <span class="details">الأسم:</span>
        <span class="details">{{order?.address?.user?.first_name}} {{order?.address?.user?.last_name}}</span>
      </div>
      <div class="box">
        <span class="details">المدينة:</span>
        <span class="details">{{order?.address?.city?.name_ar || order?.address?.international_city }}</span>
      </div>
      <div class="box">
        <span class="details">العنوان:</span>
        <span class="details">{{order?.address?.address_one}}</span>
      </div>
      <div class="box">
        <span class="details">الهاتف:</span>
        <span class="details">{{order?.address?.phone}}</span>
      </div>
      <mat-divider></mat-divider>
    </div>
  </div>
  <div class="con" *ngFor="let item of order?.order_items; let i = index">
    <div class="productItem"  dir="rtl">
      <div class="image-title">
        <img class="image" [appImageBreakPoint]="
              api + '/media/image/' + item.product.main_image_id
            " />
        <div class="nameAndQuantityContainer">
          <span class="name">
            {{ item.product.title }}
          </span>

          <div class="quantity">
            <span>
              العدد: {{getQuantity(item.product.id, fullOrder?.order_items)}}
            </span>
          </div>


          <div class="price">
            <span>
              <span>{{ getProductPrice(item.product) }}</span>
            </span>
          </div>
        </div>
      </div>
    </div>


  </div>

  <div class="footer">
    <div class="total">
      <span>المجموع: </span>
      <span class="prevues-price" [class.hereIsPromoCode]="order?.promotion">
        {{totalCurrentCurrency}} {{order?.address?.country?.currency_ar}}
      </span>

      <span *ngIf="order?.promotion">
        {{totalCurrentCurrency * ( 1 - order.promotion.store_amount / 100 ) | number}}
        {{order?.address?.country?.currency_ar}}
      </span>
    </div>

  </div>
  <div *ngIf="!order?.promotion">
    <span *ngIf="order?.address?.country?.id != 1" class="smaller">
      * سيتم خصم قيمة الطلب
      بالدولار الأمريكي ({{ totalCurrentCurrency }} {{ order?.address?.country?.currency_ar }} ما يعادل
      {{ totalUsd }} USD).
    </span>
  </div>

  <div *ngIf="order?.promotion">
    <span *ngIf="order?.address?.country?.id != 1" class="smaller">
      * سيتم خصم قيمة الطلب
      بالدولار الأمريكي ({{ totalCurrentCurrency * ( 1 - order.promotion.store_amount / 100 ) | number }} {{
      order?.address?.country?.currency_ar }} ما يعادل
      {{ totalUsd * ( 1 - order.promotion?.store_amount / 100 ) }} USD).
    </span>
  </div>

  <div class="submit-sec">
    <button class="submit" mat-stroked-button (click)="submit()">
      {{
      "الى الدفع"
      }}
      <mat-icon [ngClass]="{ valid: true }">double_arrow</mat-icon>
    </button>
  </div>
</app-form-container>
<div class="loading" *ngIf="loading">
  <mat-spinner></mat-spinner>
</div>