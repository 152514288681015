import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormContainerComponent } from './form-container.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';



@NgModule({
  declarations: [FormContainerComponent],
  exports: [FormContainerComponent],
  imports: [
    CommonModule,
    MatProgressSpinnerModule
  ]
})
export class FormContainerModule { }
