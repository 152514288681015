import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Order, OrderItem } from 'src/app/models/models';
import { OrdersService } from 'src/app/shared/services/api/orders.service';
import { environment } from 'src/environments/environment';
declare var Checkout;
interface CheckoutSession {
  id: string;
  updateStatus: string;
  version: string;
  orderID: string;
}
@Component({
  selector: 'app-payment-order',
  templateUrl: './payment-order.component.html',
  styleUrls: ['./payment-order.component.scss']
})
export class PaymentOrderComponent implements OnInit {

  constructor(private orderService: OrdersService,
    private activatedRoute: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: object,
    private router: Router,
  ) { }
  api = environment.API_URL;
  order: Order;
  fullOrder: Order;
  country: any;
  totalCurrentCurrency: any;
  totalUsd: any;
  loading: boolean = false;

  async ngOnInit() {
    const orderId = this.activatedRoute?.snapshot.params.id;
    this.orderService.getWaitingPaymentOrder(orderId).subscribe(response => {
      this.order = response[0];
      this.order.order_items = this.order.order_items.filter((item, index, self) =>
        index === self.findIndex((t) => t.product.id === item.product.id)
      );
    },
      (err) => {
        this.router.navigate(['/home']);
      },
    );

    this.orderService.getWaitingPaymentOrder(orderId).subscribe(response => {
      this.fullOrder = response[0];
      this.getTotalCurrentCurrency();
      this.getTotalUsd();
    });




    if (isPlatformBrowser(this.platformId)) {
      this.loading = true;
      await this.loadScript(
        'mainmeps',
        `${environment.GATEWAY_URL}/checkout/version/${environment.GATEWAY_API_VERSION}/checkout.js`
      );
      this.loading = false;
    }
  }


  async submit() {
    this.loading = true;
    let session = await this.orderService.completeWaitingPayment(this.order.id);
    await this.initCardPayment(session);
    Checkout.showPaymentPage();
  }

  getQuantity(productId: number, items: OrderItem[]) {
    return items?.filter(d => d.product.id == productId).length;
  }

  getTotalCurrentCurrency() {
    if (this.fullOrder.address.country.code == 'JO') {
      this.totalCurrentCurrency = this.fullOrder.order_items.map(d => d.product.jod_price).reduce((v1, v2) => v1 + v2);
      this.totalCurrentCurrency = this.totalCurrentCurrency.toFixed(2)
    } else {
      this.totalCurrentCurrency = this.fullOrder.order_items.map(d => d.product.usd_price).reduce((v1, v2) => (v1 + v2) * 100 / 100) * this.fullOrder.address.country.exchange_rate;
      this.totalCurrentCurrency = this.totalCurrentCurrency.toFixed(2)
    }
  }

  getTotalUsd() {
    this.totalUsd = this.fullOrder.order_items.map(d => d.product.usd_price).reduce((v1, v2) => v1 + v2);
  }


  getProductPrice(product: any) {
    return this.fullOrder?.address?.country?.id == 1
      ?
      (product?.jod_price).toFixed(2) + this.fullOrder?.address?.country?.currency_ar
      : (product?.usd_price * this.fullOrder?.address?.country?.exchange_rate).toFixed(2) + this.fullOrder?.address?.country?.currency_ar
  }

  async initCardPayment(session) {
    Checkout.configure({
      merchant: environment.GATEWAY_USERNAME,
      order: {
        amount: session.amount,
        currency: session.currency,
        description: session.description,
        id: session.order_id,
      },
      session: {
        id: session.session.id,
        version: session.session.version,
      },
      interaction: {
        merchant: {
          name: 'برق - Barq',
          logo: environment.DOMAIN + '/assets/barq.png',
          address: {
            line1: 'Dabuq khair aldeen almaani ',
          },
        },
        displayControl: {
          billingAddress: 'HIDE',
          customerEmail: 'HIDE',
          orderSummary: 'HIDE',
          shipping: 'HIDE',
        },
      },
    });

    return session;
  }


  loadScript(name: string, src) {
    return new Promise((resolve, reject) => {
      const script: any = document.createElement('script');
      script.type = 'text/javascript';
      script.src = src;
      if (script.readyState) {
        script.onreadystatechange = () => {
          if (
            script.readyState === 'loaded' ||
            script.readyState === 'complete'
          ) {
            resolve({ script: name, loaded: true, status: 'Loaded' });
          }
        };
      } else {
        script.onload = () => {
          resolve({ script: name, loaded: true, status: 'Loaded' });
        };
      }
      script.onerror = (error: any) => {
        console.error(error);
        resolve({ script: name, loaded: false, status: 'Loaded' });
      };
      document.getElementsByTagName('head')[0].appendChild(script);
    });
  }
}
