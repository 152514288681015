import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Inject,
  Input,
  OnInit,
  Output,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, async } from 'rxjs';
import { delay, first, map } from 'rxjs/operators';
import { Subscription, UserSubscription } from 'src/app/models/models';
import { environment } from 'src/environments/environment';
import { SubscriptionsService } from '../../services/api/subscriptions.service';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/models/app-state.model';
import { Router } from '@angular/router';
import Swiper from 'swiper';
@Component({
  selector: 'app-subscription-input',
  templateUrl: './subscription-input.component.html',
  styleUrls: ['./subscription-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SubscriptionInputComponent),
      multi: true,
    },
  ],
})
export class SubscriptionInputComponent
  implements ControlValueAccessor, OnInit
{
  // environment = environment;
  // list$: Observable<Array<Subscription>>;
  // filteredList: any;
  // @Input() theme = '';
  // @Input() type;
  // @Input() subscriptions: UserSubscription[];
  // @Output() changed = new EventEmitter<Subscription>();

  // _value: Subscription = null;

  // onChange: any;
  // onTouched: any;
  // isTouched = false;

  // k: any = [];
  // subsData: any = [];
  // dataHold: any = [];

  environment = environment;
  list$: Observable<Array<Subscription>>;
  @Input() theme = '';
  @Input() type;
  @Input() subscriptions: UserSubscription[];
  @Output() changed = new EventEmitter<Subscription>();

  _value: Subscription = null;

  @ViewChild('swiperRef') swiperRef!: ElementRef;

  onChange: any;
  onTouched: any;
  isTouched = false;
  typeIdData: any = [];

  swiperInstance!: Swiper;

  getSubId: any = [];

  constructor(
    private elementRef: ElementRef,
    public router: Router,
    @Inject(DOCUMENT) private document: Document,
    private subscriptionsService: SubscriptionsService,
    @Inject(PLATFORM_ID) private platformId: object,
    private store: Store<AppState>
  ) {}

  lists: any = [];

  async ngOnInit(): Promise<void> {
    this.subscriptionsService.getTypeId().subscribe((resultTypeId) => {
      console.log('result s_type_id', resultTypeId);

      this.typeIdData = resultTypeId;

      console.log(this.typeIdData.subscription_type_id);
    });

    if (isPlatformBrowser(this.platformId))
      this.store
        .select((store) => store.Country)
        .subscribe(async (country: any) => {
          this.list$ = this.subscriptionsService
            .getActiveSubscriptions(
              country.id,
              this.typeIdData.subscription_type_id || this.type.id
            )
            .pipe(
              delay(300),
              first(),
              map((res) => res.data)
            );

          // this.lists = this.list$.toPromise();
          // console.log(await this.lists);

          // for (let sub of await this.lists) {
          //   console.log(sub.id);
          //   this.active(sub, sub.id);
          // }
        });

    // console.log(this.lists);
    // this.injectScript('/assets/js/script.js');
  }

  // onSlideChange(event: any) {
  //   console.log('Slide changed!', event.detail);
  // }

  // ngAfterViewInit() {
  //   const swiperInstance = this.swiperRef.swiper;
  //   // Initialize any additional properties or methods
  // }

  // onSwiperInit(swiper) {
  //   // Additional initialization if necessary
  // }

  // async ngOnInit() {
  //   if (isPlatformBrowser(this.platformId))
  //     this.store
  //       .select((store) => store.Country)
  //       .subscribe((country: any) => {
  //         this.list$ = this.subscriptionsService
  //           .getActiveSubscriptions(country.id)
  //           .pipe(
  //             delay(300),
  //             first(),
  //             map((res) => res.data)
  //           );
  //       });

  //   // this.filteredList = this.list$.forEach((list) => {
  //   //   console.log(list);
  //   // });
  //   // console.log(this.filteredList);

  //   this.injectScript('/assets/js/script.js');

  //   // this.k = this.subscriptionsService.getActiveSubscriptions().toPromise();

  //   // this.list$.subscribe((d) => (this.dataHold = d));

  //   // console.log(this.k);

  //   // this.subsData = this.k.data;

  //   // console.log(this.subsData);
  // }

  // public injectScript(src: string) {
  //   if (this.document && src?.trim()) {
  //     const script = this.document.createElement('script');
  //     script.setAttribute('type', 'text/javascript');
  //     script.setAttribute('src', src.trim());
  //     this.document.head?.appendChild(script);
  //   }
  // }

  // ngAfterViewInit() {
  //   var s = document.createElement('script');
  //   s.type = 'text/javascript';
  //   s.src = '/assets/js/script.js';
  //   this.elementRef.nativeElement.appendChild(s);
  // }

  // afterScriptAdded() {
  //   const params = {
  //     width: '350px',
  //     height: '420px',
  //   };

  //   if ((typeof window as any['functionFromExternalScript']) === 'function') {
  //     window as any['functionFromExternalScript'];
  //     params;
  //   }
  // }

  // ngAfterViewInit() {
  //   this.swiperInstance = new Swiper(this.swiperRef.nativeElement, {
  //     initialSlide: 0,
  //     effect: 'coverflow',
  //     grabCursor: true,
  //     centeredSlides: true,
  //     slidesPerView: 'auto',
  //     destroySlides: true,

  //     // loop: true,
  //     // autoplay: {
  //     //   delay: 4000,

  //     //   disableOnInteraction: true,
  //     //   autoplayDisableOnInteraction: false,
  //     // },
  //     // loopedSlides: 6,

  //     // speed: 1600,

  //     coverflowEffect: {
  //       rotate: 0,
  //       stretch: 0,
  //       depth: 250,
  //       // modifier: 2,
  //       slideShadows: false,
  //     },
  //     spaceBetween: 60,

  //     hashNavigation: {
  //       watchState: true,
  //     },

  //     pagination: {
  //       el: '.swiper-pagination-2',
  //       type: 'bullets',
  //       clickable: true,
  //     },

  //     breakpoints: {
  //       // when window width is >= 640px

  //       // when window width is >= 480px
  //       745: {
  //         effect: 'coverflowEffect',
  //       },
  //       // when window width is >= 320px
  //     },
  //     on: {
  //       slideChange: () => {
  //         console.log('asdasd');
  //       },
  //     },
  //   });
  // }

  calclatePer(firstSub, sub) {
    const firstPrice = firstSub.price;
    const secondPrice = sub.price / sub.months;
    const step1 = firstPrice - secondPrice;
    const step2 = (step1 / firstPrice) * 100;
    return step2.toFixed(0);
  }

  get value(): any {
    return this._value;
  }

  set value(v: any) {
    this._value = v;
  }

  get id(): number {
    return this.value?.id;
  }

  // active(value, index) {
  //   const clickEvent = new MouseEvent('click', {
  //     bubbles: true,
  //     cancelable: true,
  //   });
  //   // // const activeIndex = index;
  //   // const activeSlide = value;
  //   // console.log(value, index);

  //   let element: HTMLElement = this.document.getElementById(
  //     'tt'
  //   ) as HTMLElement;
  //   element?.dispatchEvent(clickEvent);

  //   // console.log(event);
  //   this.value = value;
  //   this.changed.emit(value);
  //   this.update();
  // }

  selected(value) {
    this.value = value;
    this.changed.emit(value);

    this.update();
  }

  selectedOnMobile(value) {
    this.getSubId = { subscription_id: value?.id };

    this.value = value;
    this.changed.emit(value);
    this.router.navigate(['/how-to-subscription'], {
      queryParams: this.getSubId,
    });
    this.update();
  }

  touch(): void {
    this.isTouched = true;
  }

  onTouchStart() {
    console.log('Touch started!');
  }

  writeValue(value: Subscription): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
    // this.onTouched();
  }

  setDisabledState?(isDisabled: boolean): void {}

  update(): void {
    if (this.onChange) {
      if (this.router.url.includes('/order')) {
        this.onChange(this.value);
        console.log(this.value);
        const currentRoute = this.router.routerState;
        this.router.navigateByUrl(currentRoute.snapshot.url, {
          skipLocationChange: true,
        });
      } else if (this.router.url.includes('/how-to-subscription')) {
        this.onChange(this.value);
        this.router.navigate(['/how-to-subscription'], {
          queryParams: this.getSubId,
        });
      }
    }
  }

  public homeUrl = {
    home: '/home',
  };

  ngAfterViewInit() {
    //script

    var s = document.createElement('script');
    s.type = 'text/javascript';
    s.src = '/assets/js/script-subscription-slider.js';

    s.async = true;

    this.elementRef.nativeElement.appendChild(s);
  }

  afterScriptAdded() {
    const params = {
      width: '350px',
      height: '420px',
    };

    if ((typeof window as any['functionFromExternalScript']) === 'function') {
      window as any['functionFromExternalScript'];
      params;
    }
  }
}
